/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GrexUserChangePassword,
  GrexUserMe,
  GrexUserMeUpdate,
} from '../models';
import {
    GrexUserChangePasswordFromJSON,
    GrexUserChangePasswordToJSON,
    GrexUserMeFromJSON,
    GrexUserMeToJSON,
    GrexUserMeUpdateFromJSON,
    GrexUserMeUpdateToJSON,
} from '../models';

export interface ApiGrexmanagerUserMeResetPasswordCreateRequest {
    grexUserChangePassword: GrexUserChangePassword;
}

export interface ApiGrexmanagerUserMeUpdateRequest {
    grexUserMeUpdate?: GrexUserMeUpdate;
}

/**
 * 
 */
export class GrexUserMeApi extends runtime.BaseAPI {

    /**
     * change-password
     */
    async apiGrexmanagerUserMeResetPasswordCreateRaw(requestParameters: ApiGrexmanagerUserMeResetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.grexUserChangePassword === null || requestParameters.grexUserChangePassword === undefined) {
            throw new runtime.RequiredError('grexUserChangePassword','Required parameter requestParameters.grexUserChangePassword was null or undefined when calling apiGrexmanagerUserMeResetPasswordCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/me/reset-password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserChangePasswordToJSON(requestParameters.grexUserChangePassword),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * change-password
     */
    async apiGrexmanagerUserMeResetPasswordCreate(requestParameters: ApiGrexmanagerUserMeResetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserMeResetPasswordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGrexmanagerUserMeRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUserMe>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/me/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserMeFromJSON(jsonValue));
    }

    /**
     */
    async apiGrexmanagerUserMeRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUserMe> {
        const response = await this.apiGrexmanagerUserMeRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGrexmanagerUserMeUpdateRaw(requestParameters: ApiGrexmanagerUserMeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUserMeUpdate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/me/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserMeUpdateToJSON(requestParameters.grexUserMeUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserMeUpdateFromJSON(jsonValue));
    }

    /**
     */
    async apiGrexmanagerUserMeUpdate(requestParameters: ApiGrexmanagerUserMeUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUserMeUpdate> {
        const response = await this.apiGrexmanagerUserMeUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
