/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentationSuperAdmin,
} from '../models';
import {
    DocumentationSuperAdminFromJSON,
    DocumentationSuperAdminToJSON,
} from '../models';

export interface ApiGrexsuperadminDocumentationCreateRequest {
    documentationSuperAdmin: DocumentationSuperAdmin;
}

export interface ApiGrexsuperadminDocumentationDestroyRequest {
    id: number;
}

export interface ApiGrexsuperadminDocumentationListRequest {
    id?: number;
}

export interface ApiGrexsuperadminDocumentationUpdateRequest {
    id: number;
    documentationSuperAdmin: DocumentationSuperAdmin;
}

/**
 * 
 */
export class GrexSuperAdminDocumentationApi extends runtime.BaseAPI {

    /**
     * create documentation
     */
    async apiGrexsuperadminDocumentationCreateRaw(requestParameters: ApiGrexsuperadminDocumentationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentationSuperAdmin>> {
        if (requestParameters.documentationSuperAdmin === null || requestParameters.documentationSuperAdmin === undefined) {
            throw new runtime.RequiredError('documentationSuperAdmin','Required parameter requestParameters.documentationSuperAdmin was null or undefined when calling apiGrexsuperadminDocumentationCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/documentation/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentationSuperAdminToJSON(requestParameters.documentationSuperAdmin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentationSuperAdminFromJSON(jsonValue));
    }

    /**
     * create documentation
     */
    async apiGrexsuperadminDocumentationCreate(requestParameters: ApiGrexsuperadminDocumentationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentationSuperAdmin> {
        const response = await this.apiGrexsuperadminDocumentationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete documentation
     */
    async apiGrexsuperadminDocumentationDestroyRaw(requestParameters: ApiGrexsuperadminDocumentationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminDocumentationDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/documentation/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * delete documentation
     */
    async apiGrexsuperadminDocumentationDestroy(requestParameters: ApiGrexsuperadminDocumentationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexsuperadminDocumentationDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get documentation
     */
    async apiGrexsuperadminDocumentationListRaw(requestParameters: ApiGrexsuperadminDocumentationListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentationSuperAdmin>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/documentation/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentationSuperAdminFromJSON));
    }

    /**
     * get documentation
     */
    async apiGrexsuperadminDocumentationList(requestParameters: ApiGrexsuperadminDocumentationListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentationSuperAdmin>> {
        const response = await this.apiGrexsuperadminDocumentationListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update documentation
     */
    async apiGrexsuperadminDocumentationUpdateRaw(requestParameters: ApiGrexsuperadminDocumentationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentationSuperAdmin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminDocumentationUpdate.');
        }

        if (requestParameters.documentationSuperAdmin === null || requestParameters.documentationSuperAdmin === undefined) {
            throw new runtime.RequiredError('documentationSuperAdmin','Required parameter requestParameters.documentationSuperAdmin was null or undefined when calling apiGrexsuperadminDocumentationUpdate.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/documentation/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentationSuperAdminToJSON(requestParameters.documentationSuperAdmin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentationSuperAdminFromJSON(jsonValue));
    }

    /**
     * update documentation
     */
    async apiGrexsuperadminDocumentationUpdate(requestParameters: ApiGrexsuperadminDocumentationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentationSuperAdmin> {
        const response = await this.apiGrexsuperadminDocumentationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
