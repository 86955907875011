/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientTemplateReportHistory } from './ClientTemplateReportHistory';
import {
    ClientTemplateReportHistoryFromJSON,
    ClientTemplateReportHistoryFromJSONTyped,
    ClientTemplateReportHistoryToJSON,
} from './ClientTemplateReportHistory';
import type { StateEnum } from './StateEnum';
import {
    StateEnumFromJSON,
    StateEnumFromJSONTyped,
    StateEnumToJSON,
} from './StateEnum';

/**
 * 
 * @export
 * @interface ClientTemplateReportInstance
 */
export interface ClientTemplateReportInstance {
    /**
     * 
     * @type {number}
     * @memberof ClientTemplateReportInstance
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ClientTemplateReportInstance
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientTemplateReportInstance
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ClientTemplateReportInstance
     */
    nModelsMin: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTemplateReportInstance
     */
    nModelsMax?: number;
    /**
     * 
     * @type {StateEnum}
     * @memberof ClientTemplateReportInstance
     */
    state?: StateEnum;
    /**
     * 
     * @type {Array<ClientTemplateReportHistory>}
     * @memberof ClientTemplateReportInstance
     */
    history: Array<ClientTemplateReportHistory>;
}

/**
 * Check if a given object implements the ClientTemplateReportInstance interface.
 */
export function instanceOfClientTemplateReportInstance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "nModelsMin" in value;
    isInstance = isInstance && "history" in value;

    return isInstance;
}

export function ClientTemplateReportInstanceFromJSON(json: any): ClientTemplateReportInstance {
    return ClientTemplateReportInstanceFromJSONTyped(json, false);
}

export function ClientTemplateReportInstanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientTemplateReportInstance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'nModelsMin': json['n_models_min'],
        'nModelsMax': !exists(json, 'n_models_max') ? undefined : json['n_models_max'],
        'state': !exists(json, 'state') ? undefined : StateEnumFromJSON(json['state']),
        'history': ((json['history'] as Array<any>).map(ClientTemplateReportHistoryFromJSON)),
    };
}

export function ClientTemplateReportInstanceToJSON(value?: ClientTemplateReportInstance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'n_models_min': value.nModelsMin,
        'n_models_max': value.nModelsMax,
        'state': StateEnumToJSON(value.state),
        'history': ((value.history as Array<any>).map(ClientTemplateReportHistoryToJSON)),
    };
}

