/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StateEnum } from './StateEnum';
import {
    StateEnumFromJSON,
    StateEnumFromJSONTyped,
    StateEnumToJSON,
} from './StateEnum';

/**
 * 
 * @export
 * @interface ClientTemplateReport
 */
export interface ClientTemplateReport {
    /**
     * 
     * @type {number}
     * @memberof ClientTemplateReport
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ClientTemplateReport
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ClientTemplateReport
     */
    nModelsMin: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTemplateReport
     */
    nModelsMax?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientTemplateReport
     */
    name: string;
    /**
     * 
     * @type {StateEnum}
     * @memberof ClientTemplateReport
     */
    state?: StateEnum;
}

/**
 * Check if a given object implements the ClientTemplateReport interface.
 */
export function instanceOfClientTemplateReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "nModelsMin" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ClientTemplateReportFromJSON(json: any): ClientTemplateReport {
    return ClientTemplateReportFromJSONTyped(json, false);
}

export function ClientTemplateReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientTemplateReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'nModelsMin': json['n_models_min'],
        'nModelsMax': !exists(json, 'n_models_max') ? undefined : json['n_models_max'],
        'name': json['name'],
        'state': !exists(json, 'state') ? undefined : StateEnumFromJSON(json['state']),
    };
}

export function ClientTemplateReportToJSON(value?: ClientTemplateReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'n_models_min': value.nModelsMin,
        'n_models_max': value.nModelsMax,
        'name': value.name,
        'state': StateEnumToJSON(value.state),
    };
}

