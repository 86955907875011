/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StateEnum } from './StateEnum';
import {
    StateEnumFromJSON,
    StateEnumFromJSONTyped,
    StateEnumToJSON,
} from './StateEnum';

/**
 * 
 * @export
 * @interface ClientCreateTemplateReport
 */
export interface ClientCreateTemplateReport {
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateReport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateReport
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateTemplateReport
     */
    nModelsMin: number;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateTemplateReport
     */
    nModelsMax: number;
    /**
     * 
     * @type {StateEnum}
     * @memberof ClientCreateTemplateReport
     */
    state: StateEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateTemplateReport
     */
    file: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateTemplateReport
     */
    active: boolean;
}

/**
 * Check if a given object implements the ClientCreateTemplateReport interface.
 */
export function instanceOfClientCreateTemplateReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "nModelsMin" in value;
    isInstance = isInstance && "nModelsMax" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function ClientCreateTemplateReportFromJSON(json: any): ClientCreateTemplateReport {
    return ClientCreateTemplateReportFromJSONTyped(json, false);
}

export function ClientCreateTemplateReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCreateTemplateReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'nModelsMin': json['n_models_min'],
        'nModelsMax': json['n_models_max'],
        'state': StateEnumFromJSON(json['state']),
        'file': json['file'],
        'active': json['active'],
    };
}

export function ClientCreateTemplateReportToJSON(value?: ClientCreateTemplateReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'n_models_min': value.nModelsMin,
        'n_models_max': value.nModelsMax,
        'state': StateEnumToJSON(value.state),
        'file': value.file,
        'active': value.active,
    };
}

