/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface ProjectRoleRequest
 */
export interface ProjectRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectRoleRequest
     */
    id: number;
    /**
     * 
     * @type {RoleEnum}
     * @memberof ProjectRoleRequest
     */
    role: RoleEnum;
}

/**
 * Check if a given object implements the ProjectRoleRequest interface.
 */
export function instanceOfProjectRoleRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ProjectRoleRequestFromJSON(json: any): ProjectRoleRequest {
    return ProjectRoleRequestFromJSONTyped(json, false);
}

export function ProjectRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'role': RoleEnumFromJSON(json['role']),
    };
}

export function ProjectRoleRequestToJSON(value?: ProjectRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': RoleEnumToJSON(value.role),
    };
}

