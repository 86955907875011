/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SAMLServiceProvider
 */
export interface SAMLServiceProvider {
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceProvider
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceProvider
     */
    singleSignOnService: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceProvider
     */
    singleLogoutService: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLServiceProvider
     */
    x509cert: string;
}

/**
 * Check if a given object implements the SAMLServiceProvider interface.
 */
export function instanceOfSAMLServiceProvider(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityId" in value;
    isInstance = isInstance && "singleSignOnService" in value;
    isInstance = isInstance && "singleLogoutService" in value;
    isInstance = isInstance && "x509cert" in value;

    return isInstance;
}

export function SAMLServiceProviderFromJSON(json: any): SAMLServiceProvider {
    return SAMLServiceProviderFromJSONTyped(json, false);
}

export function SAMLServiceProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLServiceProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'singleSignOnService': json['singleSignOnService'],
        'singleLogoutService': json['singleLogoutService'],
        'x509cert': json['x509cert'],
    };
}

export function SAMLServiceProviderToJSON(value?: SAMLServiceProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'singleSignOnService': value.singleSignOnService,
        'singleLogoutService': value.singleLogoutService,
        'x509cert': value.x509cert,
    };
}

