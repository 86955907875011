/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleRequest } from './ProjectRoleRequest';
import {
    ProjectRoleRequestFromJSON,
    ProjectRoleRequestFromJSONTyped,
    ProjectRoleRequestToJSON,
} from './ProjectRoleRequest';

/**
 * 
 * @export
 * @interface PatchedProjectUpdateRequest
 */
export interface PatchedProjectUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectUpdateRequest
     */
    organisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectUpdateRequest
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectUpdateRequest
     */
    projectmanager?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectUpdateRequest
     */
    subregion?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectUpdateRequest
     */
    typeProject?: number;
    /**
     * 
     * @type {Array<ProjectRoleRequest>}
     * @memberof PatchedProjectUpdateRequest
     */
    roles?: Array<ProjectRoleRequest>;
}

/**
 * Check if a given object implements the PatchedProjectUpdateRequest interface.
 */
export function instanceOfPatchedProjectUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedProjectUpdateRequestFromJSON(json: any): PatchedProjectUpdateRequest {
    return PatchedProjectUpdateRequestFromJSONTyped(json, false);
}

export function PatchedProjectUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedProjectUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'organisation': !exists(json, 'organisation') ? undefined : json['organisation'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'projectmanager': !exists(json, 'projectmanager') ? undefined : json['projectmanager'],
        'subregion': !exists(json, 'subregion') ? undefined : json['subregion'],
        'typeProject': !exists(json, 'type_project') ? undefined : json['type_project'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(ProjectRoleRequestFromJSON)),
    };
}

export function PatchedProjectUpdateRequestToJSON(value?: PatchedProjectUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'organisation': value.organisation,
        'name': value.name,
        'number': value.number,
        'projectmanager': value.projectmanager,
        'subregion': value.subregion,
        'type_project': value.typeProject,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(ProjectRoleRequestToJSON)),
    };
}

