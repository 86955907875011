/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleEnum } from './ProjectRoleEnum';
import {
    ProjectRoleEnumFromJSON,
    ProjectRoleEnumFromJSONTyped,
    ProjectRoleEnumToJSON,
} from './ProjectRoleEnum';
import type { VersionModelInfoAttachmentResponse } from './VersionModelInfoAttachmentResponse';
import {
    VersionModelInfoAttachmentResponseFromJSON,
    VersionModelInfoAttachmentResponseFromJSONTyped,
    VersionModelInfoAttachmentResponseToJSON,
} from './VersionModelInfoAttachmentResponse';

/**
 * 
 * @export
 * @interface VersionModelInfoResponse
 */
export interface VersionModelInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof VersionModelInfoResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof VersionModelInfoResponse
     */
    phase?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof VersionModelInfoResponse
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    readonly lastModifiedByNameFull: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    readonly authorNameFull: string;
    /**
     * 
     * @type {ProjectRoleEnum}
     * @memberof VersionModelInfoResponse
     */
    projectRole: ProjectRoleEnum;
    /**
     * 
     * @type {Array<VersionModelInfoAttachmentResponse>}
     * @memberof VersionModelInfoResponse
     */
    attachments: Array<VersionModelInfoAttachmentResponse>;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    viewerUri: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    outlineUri: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoResponse
     */
    editorUri: string;
    /**
     * 
     * @type {Date}
     * @memberof VersionModelInfoResponse
     */
    readonly createdAt: Date;
}

/**
 * Check if a given object implements the VersionModelInfoResponse interface.
 */
export function instanceOfVersionModelInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "timestampLastModified" in value;
    isInstance = isInstance && "lastModifiedByNameFull" in value;
    isInstance = isInstance && "authorNameFull" in value;
    isInstance = isInstance && "projectRole" in value;
    isInstance = isInstance && "attachments" in value;
    isInstance = isInstance && "viewerUri" in value;
    isInstance = isInstance && "outlineUri" in value;
    isInstance = isInstance && "editorUri" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function VersionModelInfoResponseFromJSON(json: any): VersionModelInfoResponse {
    return VersionModelInfoResponseFromJSONTyped(json, false);
}

export function VersionModelInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModelInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'lastModifiedByNameFull': json['last_modified_by_name_full'],
        'authorNameFull': json['author_name_full'],
        'projectRole': ProjectRoleEnumFromJSON(json['project_role']),
        'attachments': ((json['attachments'] as Array<any>).map(VersionModelInfoAttachmentResponseFromJSON)),
        'viewerUri': json['viewer_uri'],
        'outlineUri': json['outline_uri'],
        'editorUri': json['editor_uri'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function VersionModelInfoResponseToJSON(value?: VersionModelInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'phase': value.phase,
        'project_role': ProjectRoleEnumToJSON(value.projectRole),
        'attachments': ((value.attachments as Array<any>).map(VersionModelInfoAttachmentResponseToJSON)),
        'viewer_uri': value.viewerUri,
        'outline_uri': value.outlineUri,
        'editor_uri': value.editorUri,
    };
}

