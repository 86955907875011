/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `NoMapViewerConnection` - NoMapViewerConnection
 * * `BigArea` - BigArea
 * * `NoData` - NoData
 * * `NoERROR` - NoERROR
 * @export
 */
export const MapviewerErrorEnum = {
    NoMapViewerConnection: 'NoMapViewerConnection',
    BigArea: 'BigArea',
    NoData: 'NoData',
    NoError: 'NoERROR'
} as const;
export type MapviewerErrorEnum = typeof MapviewerErrorEnum[keyof typeof MapviewerErrorEnum];


export function MapviewerErrorEnumFromJSON(json: any): MapviewerErrorEnum {
    return MapviewerErrorEnumFromJSONTyped(json, false);
}

export function MapviewerErrorEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapviewerErrorEnum {
    return json as MapviewerErrorEnum;
}

export function MapviewerErrorEnumToJSON(value?: MapviewerErrorEnum | null): any {
    return value as any;
}

