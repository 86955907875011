import React from "react";
import "./LoginScreen.css";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Alert from "@shared/components/Alert";
import Button from "@shared/components/Button";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import LoginBackground from "@shared/components/LoginBackground";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";

interface LoginScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class LoginScreen extends React.Component<LoginScreenProps> {
  state = { email: "", password: "" };

  render() {
    return (
      <div id="LoginScreen-Container">
        <div id="LoginScreen-Section">
          <GrexManagerLogo />
          <div id="LoginScreen-LoginHolder">
            <SectionTitle>Inloggen | SuperAdmin</SectionTitle>
            {(() => {
              if (this.props.state.LoginScreen.login_error !== "") {
                return (
                  <Alert
                    Error={true}
                    Message={this.props.state.LoginScreen.login_error}
                  ></Alert>
                );
              }
            })()}

            <div className="label">E-mailadres</div>
            <TextInput
              type={TextInputType.Email}
              value={this.state.email}
              onChange={(newValue: string) => {
                this.setState({
                  email: newValue,
                  password: this.state.password,
                });
              }}
            ></TextInput>
            <div className="label">Wachtwoord</div>
            <TextInput
              type={TextInputType.Password}
              value={this.state.password}
              onChange={(newValue: string) => {
                this.setState({ email: this.state.email, password: newValue });
              }}
            ></TextInput>

            <Button
              onClick={() => {
                this.props.stateHandler.login(
                  this.state.email,
                  this.state.password,
                  this.props.updateStateCallback
                );
              }}
            >
              Inloggen
            </Button>
          </div>
        </div>
        <LoginBackground />
      </div>
    );
  }
}
