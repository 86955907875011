/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrexUserTemplateReportListResponse
 */
export interface GrexUserTemplateReportListResponse {
    /**
     * 
     * @type {number}
     * @memberof GrexUserTemplateReportListResponse
     */
    templateReportId: number;
    /**
     * 
     * @type {string}
     * @memberof GrexUserTemplateReportListResponse
     */
    templateReportName: string;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserTemplateReportListResponse
     */
    access: boolean;
    /**
     * 
     * @type {number}
     * @memberof GrexUserTemplateReportListResponse
     */
    user: number;
}

/**
 * Check if a given object implements the GrexUserTemplateReportListResponse interface.
 */
export function instanceOfGrexUserTemplateReportListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateReportId" in value;
    isInstance = isInstance && "templateReportName" in value;
    isInstance = isInstance && "access" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GrexUserTemplateReportListResponseFromJSON(json: any): GrexUserTemplateReportListResponse {
    return GrexUserTemplateReportListResponseFromJSONTyped(json, false);
}

export function GrexUserTemplateReportListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserTemplateReportListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateReportId': json['templateReport_id'],
        'templateReportName': json['templateReport_name'],
        'access': json['access'],
        'user': json['user'],
    };
}

export function GrexUserTemplateReportListResponseToJSON(value?: GrexUserTemplateReportListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateReport_id': value.templateReportId,
        'templateReport_name': value.templateReportName,
        'access': value.access,
        'user': value.user,
    };
}

