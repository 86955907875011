/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.06.13.57
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrexUserSubregionListResponse
 */
export interface GrexUserSubregionListResponse {
    /**
     * 
     * @type {number}
     * @memberof GrexUserSubregionListResponse
     */
    subregionId: number;
    /**
     * 
     * @type {string}
     * @memberof GrexUserSubregionListResponse
     */
    subregionDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserSubregionListResponse
     */
    access: boolean;
    /**
     * 
     * @type {number}
     * @memberof GrexUserSubregionListResponse
     */
    user: number;
}

/**
 * Check if a given object implements the GrexUserSubregionListResponse interface.
 */
export function instanceOfGrexUserSubregionListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subregionId" in value;
    isInstance = isInstance && "subregionDescription" in value;
    isInstance = isInstance && "access" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GrexUserSubregionListResponseFromJSON(json: any): GrexUserSubregionListResponse {
    return GrexUserSubregionListResponseFromJSONTyped(json, false);
}

export function GrexUserSubregionListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserSubregionListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subregionId': json['subregion_id'],
        'subregionDescription': json['subregion_description'],
        'access': json['access'],
        'user': json['user'],
    };
}

export function GrexUserSubregionListResponseToJSON(value?: GrexUserSubregionListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subregion_id': value.subregionId,
        'subregion_description': value.subregionDescription,
        'access': value.access,
        'user': value.user,
    };
}

